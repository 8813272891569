import { GetServerSidePropsContext } from "next";

const serverUrl = process.env.NEXT_PUBLIC_SERVER_URL;

interface SuccessResponse<T> {
  status: 200;
  response: T;
}

interface UnsuccessfulResponse {
  status: number;
  message?: string;
}

export type Response<T> = SuccessResponse<T> | UnsuccessfulResponse;

export function isSuccess<T>(
  response: Response<T>
): response is SuccessResponse<T> {
  return response.status === 200;
}

export function isUnauthorized<T>(
  response: Response<T>
): response is UnsuccessfulResponse {
  return response.status === 401;
}

export default async function api<T, Body extends object = {}>(
  url: string,
  method: "GET" | "POST" | "DELETE" | "PUT" = "GET",
  {
    body: unstringifiedBody,
    absolute,
    context,
    cookie: cookieFromParams,
  }: {
    body?: Body;
    absolute?: boolean;
    cookie?: string;
    context?: GetServerSidePropsContext; // TODO: deprecate this in favor of just passing the cookie
  } = {
    absolute: false,
  }
): Promise<Response<T>> {
  const body =
    (method === "POST" || method === "PUT") && unstringifiedBody
      ? JSON.stringify(unstringifiedBody)
      : undefined;

  const headers: RequestInit["headers"] = {
    "Content-Type": "application/json",
  };

  const cookie = cookieFromParams ?? context?.req?.headers.cookie;
  if (cookie != null) {
    headers.cookie = cookie;
  }

  return fetch(absolute ? `${serverUrl}${url}` : url, {
    method,
    credentials: "include",
    body,
    headers,
  })
    .then(async (res) => {
      if (res.headers.get("content-type")?.includes("application/json")) {
        return { status: res.status, response: await res.json() };
      }

      return { status: res.status, response: await res.text() };
    })
    .then(({ response, status }) => {
      if (status === 200) {
        const value: SuccessResponse<T> = {
          status: 200,
          response: response as T,
        };

        return value;
      }

      const value: UnsuccessfulResponse = { status, message: response.message };

      return value;
    });
}
